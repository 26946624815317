import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import { Link } from "gatsby"

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

const PresentationPage = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('campaign-timeline');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;
  
    return <div className="pre-wrapper">
      
      <Header />
    
      <div className="section-wrapper ">
          <Container>
            <h2>{article_data && article_data.Title}
                <div className={"title_nav"}>
                  <Link to={config.custom_prop_datetime} className="right-icon" title="Next">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                      </svg>
                  </Link>
              </div>
            </h2>
            <p className="fig-content font_20">{article_data && HTMLReactParser(article_data.Content)}</p>
            
            <div className="content-sec">

                    
                    <div className="planning-wrapper">
                    <ul>
                        {
                            article_data && article_data.Modules && article_data.Modules[0].Campaign_Steps.map((campaign, index) => {
                                return(
                                    <li>
                                        <span>step {campaign.Step}</span>
                                        <div className="planning-content">
                                            <h3>{campaign.Title}</h3>
                                            <p>{campaign.Content}</p>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    </div>
                    {/* planning-wrapper */}
 
            </div>
            {/* content-sec */}
            </Container>
        </div>
      
      <NavLink tab_name="Schedule" prevLink={config.custom_launch} nextLink={config.custom_360_photographer} />

    </div>
    // pre-wrapper
}

export default PresentationPage
